section#schedule {
  background: rgb(241, 226, 186);

  h6 {
    color: theme-color("primary");

  }
  h5{
    font-size: .8em;
    font-weight: 600;
  }
  dl{
    font-size: .9em;
  }
  dt {
    font-family: 'Source Sans Pro', monospace;
    font-weight: 600;
    text-align: left;
    @media (min-width: 768px) {

    }

  }
  dd {
  margin-left: 1em;
    font-size: .95em;
  }

}