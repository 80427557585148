section#programming {
  background: rgb(205, 255, 218);
  position: relative;
  //background-image: url("/img/event-cover.jpg");
  //background-position: center;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  z-index: 1;

  img{
    max-height: 160px;
  }

}