html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

body {
  font-family: 'Convergence', sans-serif !important;
}
a {
  color: $link-color;
  -webkit-transition: all .35s;
  -moz-transition: all .35s;
  transition: all .35s;
}

a:hover, a:focus {
  color: $link-hover-color;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: white;
}

p {
  //font-size: 18px;
}

section {
  padding: 100px 0;

  .section-heading {
    letter-spacing: 1px;
    margin-bottom: 1.1em;
  }
}

.bg-pc2018 {
  background: #88ceff;
  //background: -webkit-linear-gradient(120deg, rgb(186, 77, 255), rgb(83, 184, 255), rgb(0, 255, 153));
  //background: linear-gradient(120deg, rgb(186, 77, 255), rgb(83, 184, 255), rgb(0, 255, 153));
}

div.card.sold {
  background: url("/img/sold.png") no-repeat top;
  min-height: 460px;

}

iframe[name="google_conversion_frame"] {
  display: none;
}


.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
}