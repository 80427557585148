section#advertise {

  position: relative;
  /*padding: 250px 0;*/
  background-image: url("/img/advertise-cover.jpg");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;

  .card-text {
    font-size: .95em;
  }

  .ad-ex{
    max-height: 48px;
  }
}
