section#event {
  background: rgb(241, 226, 186);

  position: relative;
  /*padding: 250px 0;*/
  background-image: url("/img/event-cover.jpg");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;

  h4{
    color:  theme-color("primary");


  }



}