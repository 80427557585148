section#registration {
  background: rgb(205, 236, 255);
  position: relative;
  //background-image: url("/img/event-cover.jpg");
  //background-position: center;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  z-index: 1;
}