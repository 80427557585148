section#sponsor {

  position: relative;
  /*padding: 250px 0;*/
  background-image: url("/img/sponsorship-cover.jpg");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;

  text-shadow: #dea375 2px 2px 4px;

  & a.btn{
    text-shadow: none;
  }

}

section#sponsor-table {

  padding: 24px 0;

  .event-header {
    color: theme-color("danger");
    //background-color: theme-color("secondary");
    font-family: 'Raleway', sans-serif;
    font-size: 0.9em;
    letter-spacing: normal;
    text-align: center;
    font-weight: 700;
  }

  a.date, a.date {

    border-bottom: 1px dotted theme-color("primary");
    margin-bottom: 12px;
    font-size: 0.8em;
    letter-spacing: 1px;


  }

}

.stations .card {
  min-height: 325px;
}

li#v-pills-technical-conference, li#v-pills-workshop {
  text-transform: uppercase;
  //text-align: center;

}