section#exhibit {
  background: rgb(65, 65, 65);
  position: relative;
  /*padding: 250px 0;*/
  //background-image: url("/img/exhibit-cover.jpg");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;
  color: $gray-200;

  table td, table th{
    font-family: $font-family-monospace;
    &.member{
      background-color: rgba(202, 155, 15, 0.51) !important;
    }
  }
  h5{
    font-size: 16px;
  }




}

