section#floorplan{
  background-color: rgba(248, 249, 250, 0.50);
  position: relative;
  /*padding: 250px 0;*/
  background-image: url("/img/floor-cover.png");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;
}