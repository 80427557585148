header.masthead {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  background: rgb(51, 112, 154);


  background-position: top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &.attend{
    background-image: url('/img/attendees.jpg');
  }
  &.exhibit{
    background-image: url('/img/exhibit-cover-2.jpg');
  }
}


header.masthead .header-content h2 {
  text-shadow: black 1px 1px 2px;
}

@media (min-width: 992px) {
  header.masthead {
    //height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
  }

  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }
}

header.masthead .overlay{
    //background-color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.85);
  padding: 2em;
  z-index: 1;


}

#go-down{

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto .2rem;
  color: white;
}